<template>
  <v-row>
    <v-col xl="4" lg="12" md="12" sm="12">
      <v-card class="mb-10" :loading="loading">
        <v-card-title
          class="blue-grey lighten-3 white--text"
        >
          DB Schema Project: {{ project.name }}
        </v-card-title>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Require Down Migration</v-list-item-title>
              <v-list-item-subtitle>{{ project.requireDownMigration ? 'Yes' : 'No' }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            @click="$router.push({
                name: 'db-schema-project-upsert',
                params: { projectID }
              })"
          >
            Edit Project
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col xl="4" lg="6" md="12" sm="12">
      <v-card :loading="loading">
        <v-card-title
          class="blue-grey lighten-3 white--text"
        >
          Migrations
          <v-spacer/>
          <v-text-field
            v-model="migrationSearch"
            label="Search"
            single-line
            hide-details
            dark
            dense
          />
          <v-spacer/>
          <v-btn
            class="mr-4"
            color="primary"
            :disabled="createMigrationBlocked"
            :dark="!createMigrationBlocked"
            fab
            x-small
            @click="
                  $router.push({
                    name: 'db-schema-project-migration-upsert',
                    params: { projectID }
                  })"
          >
            <v-icon dark>fas fa-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="!hasDeploymentDummy || !hasDeploymentProduction">
          <v-alert v-if="!hasDeploymentDummy || !hasDeploymentProduction" class="mt-7" type="warning">
            Missing essential deployments required before first migration can be added.
          </v-alert>
        </v-card-text>
        <v-data-table
          v-else
          :headers="migrationHeaders"
          :items="migrationList || []"
          :loading="migrationList === null"
          :search="migrationSearch"
        >
          <template v-slot:item.name="{ item }">
            <v-btn
              color="primary"
              dark
              small
              text
              :to="{
                  name: 'db-schema-project-migration-detail',
                  params: { projectID, migrationID: item.id }
                }">
              {{ item.name }}
            </v-btn>
          </template>
          <template v-slot:item.status="{ item }">
            {{ statusLabel(item) }}
          </template>
          <template v-slot:item.statusUpdated="{ item }">
            {{ statusUpdated(item) }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col xl="4" lg="6" md="12" sm="12">
      <v-card :loading="deploymentList === null">
        <v-card-title
          class="blue-grey lighten-3 white--text"
        >
          <span class="text-h6">Deployments</span>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-4"
                color="primary"
                :disabled="deploymentList === null"
                :dark="deploymentList !== null"
                fab
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>fas fa-plus</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="deployment in deploymentAddList"
                :key="`add-deployment-${deployment.type.value}`"
                :disabled="deployment.disabled"
                :to="deployment.disabled ? undefined : {
                  name: 'db-schema-project-deployment-upsert',
                  params: { projectID, type: deployment.type.value }
                }"
              >
                <v-list-item-title>Add {{ deployment.title }} Deployment</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <v-card-text class="py-0">
          <v-timeline v-if="deploymentList !== null" dense>
            <v-timeline-item v-if="!hasDeploymentDummy" :color="colorDummy">
              <v-alert type="warning">
                <v-row align="center">
                  <v-col class="grow">
                    You have not setup a dummy development, please add one so your migrations can be tested.
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      color="white"
                      fab
                      x-small
                      @click="
                        $router.push({
                          name: 'db-schema-project-deployment-upsert',
                          params: { projectID, type: deploymentTypeDummy }
                        })"
                    >
                      <v-icon color="primary">fas fa-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-timeline-item>
            <v-timeline-item
              v-for="deployment of deploymentSortedList"
              :key="deployment.id"
              :color="deployment.timelineColor"
              small
            >
              {{ deployment.name }}
              <v-btn
                icon
                x-small
                @click="
                        $router.push({
                          name: 'db-schema-project-deployment-upsert',
                          params: { projectID, type: deployment.type, deploymentID: deployment.id }
                        })"
              >
                <v-icon :color="deployment.timelineColor">fas fa-pencil</v-icon>
              </v-btn>
            </v-timeline-item>
            <v-timeline-item v-if="!hasDeploymentProduction" :color="colorProduction">
              <v-alert type="warning">
                <v-row align="center">
                  <v-col class="grow">
                    You have not setup a production development, please add a final destination for your migrations.
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      color="white"
                      fab
                      x-small
                      @click="
                        $router.push({
                          name: 'db-schema-project-deployment-upsert',
                          params: { projectID, type: deploymentTypeProduction }
                        })"
                    >
                      <v-icon color="primary">fas fa-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import dbSchema from "@/data/dbSchema";
import {db} from "@/firestore";

export default {
  name: "PageDbSchemaProjectDetail",
  data() {
    return {
      deploymentList: null,
      loading: true,
      migrationList: null,
      migrationHeaders: [
        {text: "Name", value: "name"},
        {text: "Status", value: "status"},
        {text: "Last Updated", value: "statusUpdated"},
      ],
      migrationSearch: "",
      project: {},
    };
  },
  computed: {
    colorDev() {
      return "light-green";
    },
    colorDummy() {
      return "light-blue";
    },
    colorPreProduction() {
      return "purple";
    },
    colorProduction() {
      return "deep-orange";
    },
    createMigrationBlocked() {
      if (this.migrationList === null || this.deploymentList === null) {
        return true;
      }
      return !!this.migrationList.filter(m => m.status !== dbSchema.MIGRATION_STATUS.COMPLETE.value).length ||
        !this.hasDeploymentDummy || !this.hasDeploymentProduction;
    },
    deploymentAddList() {
      return [
        {title: 'Dummy', type: dbSchema.DEPLOYMENT_TYPE.DUMMY, disabled: !!this.deploymentDummyList.length},
        {title: 'Developer', type: dbSchema.DEPLOYMENT_TYPE.DEV, disabled: false},
        {title: 'Pre Production', type: dbSchema.DEPLOYMENT_TYPE.PREPRODUCTION, disabled: false},
        {title: 'Production', type: dbSchema.DEPLOYMENT_TYPE.PRODUCTION, disabled: !!this.deploymentProductionList.length},
      ];
    },
    deploymentDummyList() {
      return this.deploymentList.filter(d => d.type === dbSchema.DEPLOYMENT_TYPE.DUMMY.value);
    },
    deploymentProductionList() {
      return this.deploymentList.filter(d => d.type === dbSchema.DEPLOYMENT_TYPE.PRODUCTION.value);
    },
    deploymentSortedList() {
      return [
        ...this.deploymentDummyList.map(d => ({...d, id: d.id, timelineColor: this.colorDummy})),
        ...this.deploymentList.filter(d => d.type === dbSchema.DEPLOYMENT_TYPE.DEV.value).
          sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          }).
          map(d => ({...d, id: d.id, timelineColor: this.colorDev})),
        ...this.deploymentList.filter(d => d.type === dbSchema.DEPLOYMENT_TYPE.PREPRODUCTION.value).
          sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          }).
          map(d => ({...d, id: d.id, timelineColor: this.colorPreProduction})),
        ...this.deploymentProductionList.
          map(d => ({...d, id: d.id, timelineColor: this.colorProduction})),
      ];
    },
    hasDeploymentDummy() {
      return !!this.deploymentList.filter(d => d.type === dbSchema.DEPLOYMENT_TYPE.DUMMY.value).length;
    },
    hasDeploymentProduction() {
      return !!this.deploymentList.filter(d => d.type === dbSchema.DEPLOYMENT_TYPE.PRODUCTION.value).length;
    },
    deploymentTypeDummy() {
      return dbSchema.DEPLOYMENT_TYPE.DUMMY.value;
    },
    deploymentTypeProduction() {
      return dbSchema.DEPLOYMENT_TYPE.PRODUCTION.value;
    },
    projectID() {
      return this.$route.params.projectID;
    },
  },
  watch: {
    project() {
      this.loading = false;
    },
  },
  methods: {
    statusLabel(item) {
      const status = Object.values(dbSchema.MIGRATION_STATUS).find(s => s.value === item.status);
      return status ? status.label : `Unknown: ${status}`;
    },
    statusUpdated(item) {
      return moment(item.statusUpdated.seconds * 1000).fromNow();
    },
  },
  firestore() {
    return {
      deploymentList: db.collection("db-schema-project").doc(this.projectID).collection("deployment"),
      migrationList: db.collection("db-schema-project").
        doc(this.projectID).
        collection("migration").
        orderBy("statusUpdated", "desc"),
      project: db.collection("db-schema-project").doc(this.projectID),
    };
  },
};
</script>

<style scoped>

</style>
