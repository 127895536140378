import {functions} from "../../firestore";

export default class {
  constructor(projectID, migrationID, deploymentID) {
    this.projectID    = projectID;
    this.migrationID  = migrationID;
    this.deploymentID = deploymentID;
  }

  trigger() {
    return new Promise((resolve) => {
      functions.httpsCallable("shepherd/runDbSchemaDeploy")({
        projectID: this.projectID,
        migrationID: this.migrationID,
        deploymentID: this.deploymentID
      }).then((result) => {
        resolve(result.data);
      });
    });
  }
}
