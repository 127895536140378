var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"xl":"4","lg":"12","md":"12","sm":"12"}},[_c('v-card',{staticClass:"mb-10",attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"blue-grey lighten-3 white--text"},[_vm._v(" DB Schema Project: "+_vm._s(_vm.project.name)+" ")]),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Require Down Migration")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.project.requireDownMigration ? 'Yes' : 'No'))])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.$router.push({
              name: 'db-schema-project-upsert',
              params: { projectID: _vm.projectID }
            })}}},[_vm._v(" Edit Project ")])],1)],1)],1),_c('v-col',{attrs:{"xl":"4","lg":"6","md":"12","sm":"12"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"blue-grey lighten-3 white--text"},[_vm._v(" Migrations "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":"","dark":"","dense":""},model:{value:(_vm.migrationSearch),callback:function ($$v) {_vm.migrationSearch=$$v},expression:"migrationSearch"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","disabled":_vm.createMigrationBlocked,"dark":!_vm.createMigrationBlocked,"fab":"","x-small":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'db-schema-project-migration-upsert',
                  params: { projectID: _vm.projectID }
                })}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fas fa-plus")])],1)],1),(!_vm.hasDeploymentDummy || !_vm.hasDeploymentProduction)?_c('v-card-text',[(!_vm.hasDeploymentDummy || !_vm.hasDeploymentProduction)?_c('v-alert',{staticClass:"mt-7",attrs:{"type":"warning"}},[_vm._v(" Missing essential deployments required before first migration can be added. ")]):_vm._e()],1):_c('v-data-table',{attrs:{"headers":_vm.migrationHeaders,"items":_vm.migrationList || [],"loading":_vm.migrationList === null,"search":_vm.migrationSearch},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('v-btn',{attrs:{"color":"primary","dark":"","small":"","text":"","to":{
                name: 'db-schema-project-migration-detail',
                params: { projectID: _vm.projectID, migrationID: item.id }
              }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.status",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.statusLabel(item))+" ")]}},{key:"item.statusUpdated",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.statusUpdated(item))+" ")]}}])})],1)],1),_c('v-col',{attrs:{"xl":"4","lg":"6","md":"12","sm":"12"}},[_c('v-card',{attrs:{"loading":_vm.deploymentList === null}},[_c('v-card-title',{staticClass:"blue-grey lighten-3 white--text"},[_c('span',{staticClass:"text-h6"},[_vm._v("Deployments")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"primary","disabled":_vm.deploymentList === null,"dark":_vm.deploymentList !== null,"fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fas fa-plus")])],1)]}}])},[_c('v-list',_vm._l((_vm.deploymentAddList),function(deployment){return _c('v-list-item',{key:`add-deployment-${deployment.type.value}`,attrs:{"disabled":deployment.disabled,"to":deployment.disabled ? undefined : {
                name: 'db-schema-project-deployment-upsert',
                params: { projectID: _vm.projectID, type: deployment.type.value }
              }}},[_c('v-list-item-title',[_vm._v("Add "+_vm._s(deployment.title)+" Deployment")])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"py-0"},[(_vm.deploymentList !== null)?_c('v-timeline',{attrs:{"dense":""}},[(!_vm.hasDeploymentDummy)?_c('v-timeline-item',{attrs:{"color":_vm.colorDummy}},[_c('v-alert',{attrs:{"type":"warning"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" You have not setup a dummy development, please add one so your migrations can be tested. ")]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"color":"white","fab":"","x-small":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'db-schema-project-deployment-upsert',
                        params: { projectID: _vm.projectID, type: _vm.deploymentTypeDummy }
                      })}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fas fa-plus")])],1)],1)],1)],1)],1):_vm._e(),_vm._l((_vm.deploymentSortedList),function(deployment){return _c('v-timeline-item',{key:deployment.id,attrs:{"color":deployment.timelineColor,"small":""}},[_vm._v(" "+_vm._s(deployment.name)+" "),_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'db-schema-project-deployment-upsert',
                        params: { projectID: _vm.projectID, type: deployment.type, deploymentID: deployment.id }
                      })}}},[_c('v-icon',{attrs:{"color":deployment.timelineColor}},[_vm._v("fas fa-pencil")])],1)],1)}),(!_vm.hasDeploymentProduction)?_c('v-timeline-item',{attrs:{"color":_vm.colorProduction}},[_c('v-alert',{attrs:{"type":"warning"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" You have not setup a production development, please add a final destination for your migrations. ")]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"color":"white","fab":"","x-small":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'db-schema-project-deployment-upsert',
                        params: { projectID: _vm.projectID, type: _vm.deploymentTypeProduction }
                      })}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fas fa-plus")])],1)],1)],1)],1)],1):_vm._e()],2):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }