<template>
  <v-card :disabled="mode === 'loading'">
    <v-card-title v-if="mode === 'loading'">
      Loading...
    </v-card-title>
    <v-card-title v-if="mode === 'create'">
      Create DB Schema Deployment: {{ typeLabel }}
    </v-card-title>
    <v-card-title v-if="mode === 'edit'">
      Edit DB Schema Deployment: {{ typeLabel }}
    </v-card-title>
    <v-card-text>
      <v-form
        v-if="mode !== 'loading'"
        ref="deploymentForm"
      >
        <v-text-field
          v-model="name"
          label="Name"
          :rules="nameRules"
        />
        <v-text-field
          v-model="dbName"
          label="Database Name"
          :rules="dbNameRules"
        />
        <v-text-field
          v-model="gcpProject"
          label="GCP Project Name"
          :rules="gcpRules"
        />
        <v-text-field
          v-model="gcpInstance"
          label="GCP Instance Name"
          :rules="gcpRules"
        />
        <v-select
          v-model="secret"
          :disabled="!secretList.length"
          :items="secretList"
          :loading="!secretList.length"
          :rules="secretRules"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="mr-4"
        @click="cancel"
      >
        Cancel
      </v-btn>
      <v-spacer/>
      <v-btn
        color="primary"
        @click="submitForm"
      >
        {{ mode === "create" ? "Create Deployment" : "Edit Deployment" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";
import {db} from "@/firestore";
import dbSchema from "@/data/dbSchema";
import KubernetesSecret from '@/model/kubernetes/Secret';
const kubernetesSecret = new KubernetesSecret();

export default {
  name: "PageDbSchemaProjectDeploymentUpsert",
  data() {
    return {
      dbName: "",
      dbNameRules: [
        v => !!v || "Please enter a DB name",
        v => !!v.match(/^[a-zA-Z][a-zA-Z0-9_]*[a-zA-Z0-9]$/) || "Please enter a valid DB name"
      ],
      gcpInstance: "central",
      gcpProject: "digital-intelligence-208511",
      gcpRules: [
        v => !!v || "Please enter a GCP value"
      ],
      mode: "loading",
      name: "",
      nameTaken: true,
      nameRules: [
        v => !!v || "Please enter a deployment name",
        v => this.nameTaken === true || this.nameTaken,
      ],
      secret: "",
      secretList: [],
      secretRules: [
        v => !!v || "Please select a deployment secret",
      ],
      typeRules: [
        v => !!v || "Please enter a deployment type",
      ],
    };
  },
  computed: {
    deploymentData() {
      return {
        dbName: this.dbName.trim(),
        gcpInstance: this.gcpInstance.trim(),
        gcpProject: this.gcpProject.trim(),
        name: this.name.trim(),
        secret: this.secret,
        type: this.type,
      };
    },
    deploymentID() {
      return this.$route.params.deploymentID;
    },
    projectID() {
      return this.$route.params.projectID;
    },
    type() {
      return this.$route.params.type;
    },
    typeLabel() {
      const type = Object.values(dbSchema.DEPLOYMENT_TYPE).find(d => d.value === this.type);
      return type ? type.label : false;
    }
  },
  mounted() {
    if (!this.typeLabel) {
      this.$notify({
        type: "error",
        title: "Invalid Deployment Type",
        text: `Invalid type: ${this.type}`,
      });
      this.$router.push({name: "db-schema-project-detail", params: {projectID: this.projectID}});
    } else if (this.deploymentID) {
      db.collection("db-schema-project").
        doc(this.projectID).
        collection("deployment").
        doc(this.deploymentID).
        get().
        then((project) => {
          if (!project.exists) {
            this.$notify({
              type: "error",
              title: "Missing Deployment",
              text: `Cannot find a deployment in the system under the ID: ${this.projectID}/${this.deploymentID}`,
            });
            this.$router.push({name: "db-schema-project-detail", params: {projectID: this.projectID}});
          } else {
            for (const key of Object.keys(project.data())) {
              if (["dbName", "gcpInstance", "gcpProject", "name", "secret", "type"].includes(key)) {
                this[key] = project.data()[key];
              }
            }
            this.mode = "edit";
          }
        });
    } else {
      this.mode = "create";
    }
    kubernetesSecret.getDbSchemaSecretList().then((secrets) => {
      Object.keys(secrets).forEach((secretId) => {
        this.secretList.push({
          value: secretId,
          text: secrets[secretId],
        });
      });
    });
  },
  methods: {
    cancel() {
      this.$router.push({name: "db-schema-project-detail", params: {projectID: this.projectID}});
    },
    submitForm() {
      if (this.$refs.deploymentForm.validate()) {
        switch (this.mode) {
          case "create":
            db.collection("db-schema-project").
              doc(this.projectID).
              collection("deployment").
              add(this.deploymentData).
              then((document) => {
                this.$router.push({
                  name: "db-schema-project-detail",
                  params: {projectID: this.projectID},
                });
              });
            break;

          case "edit":
            db.collection("db-schema-project").
              doc(this.projectID).
              collection("deployment").
              doc(this.deploymentID).
              update(this.deploymentData).
              then(() => {
                this.$router.push({
                  name: "db-schema-project-detail",
                  params: {projectID: this.projectID},
                });
              });
            break;
          default:
            this.$notify({
              type: "error",
              title: "Unknown Mode",
              text: `Cannot action unknown mode: ${this.mode}`,
            });
            break;
        }
      } else {
        this.$notify({
          type: "warning",
          title: "Validation Failed",
          text: `Please correct the errors with your form`,
        });
      }
    },
  },
  watch: {
    name: _.debounce(function(value){
      const firestore = db;
      this.nameTaken  = true;
      this.$refs.deploymentForm.validate();
      if (value !== "") {
        firestore.collection("db-schema-project").
          doc(this.projectID).
          collection("deployment").
          where("name", "==", value.trim()).
          get().
          then((existing) => {
            if (existing.size > 0 && existing.docs[0].id !== this.deploymentID) {
              this.nameTaken = "A deployment with this name already exists";
              this.$refs.deploymentForm.validate();
            }
          });
      }
    }, 250)
  },
};
</script>

<style scoped>
</style>
