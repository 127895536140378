<template>
  <v-card>
    <v-card-title>
      DB Schema Project List
      <v-spacer/>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        hide-details
      />
      <v-spacer/>
      <v-btn
        color="green"
        dark
        :to="{name: 'db-schema-project-upsert'}"
      >
        <v-icon class="mr-2" small>fal fa-plus</v-icon>
        Add Project
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="schema"
      :search="search"
    >
      <template v-slot:item.name="{ item }">
        <v-btn
          color="primary"
          dark
          small
          text
          :to="{
                  name: 'db-schema-project-detail',
                  params: { projectID: item.id }
                }">
          {{ item.name }}
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {db} from "@/firestore";

export default {
  name: "PageDbSchemaProjectList",
  data() {
    return {
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Last Production Update', value: 'lastProdUpdate'},
      ],
      search: "",
      schema: []
    }
  },
  firestore: {
    schema: db.collection('db-schema-project'),
  }
};
</script>

<style scoped>

</style>
